import React, { useMemo } from "react";

import { useHeaderStore } from "@stores/useHeaderStore";

import * as S from "./styles";

export interface ScrollElementProps {
  additionalOffset?: number;
  id?: string;
}

export const ScrollElement = ({
  id,
  additionalOffset = 0,
}: ScrollElementProps) => {
  const {
    header: { height },
  } = useHeaderStore();

  const offsetHeight = useMemo(
    () => height + additionalOffset,
    [height, additionalOffset]
  );

  return (
    <S.ScrollElementWrapper
      id={id ?? ""}
      style={{ top: `-${offsetHeight}px` }}
    />
  );
};
