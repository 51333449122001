import Image from "next/image";
import { FullWidthBannerSliceDefaultPrimary } from "prismicio-types";
import React from "react";

import { useIsMdUp } from "@hooks/responsive";

import * as S from "../../styles";

type ImageBackgroundProps = Pick<FullWidthBannerSliceDefaultPrimary, "image">;

export const ImageBackground = ({ image }: ImageBackgroundProps) => {
  const isMdUp = useIsMdUp();
  const url = isMdUp ? image?.url : image?.mobile?.url ?? image?.url;

  if (!image?.url) {
    return null;
  }

  return (
    <S.ImageWrapper>
      {url && (
        <Image
          alt={image?.alt ?? ""}
          layout="fill"
          objectFit="cover"
          src={url}
        />
      )}
    </S.ImageWrapper>
  );
};
