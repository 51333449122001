import { FullWidthBannerSliceImageOnlyPrimary } from "prismicio-types";
import React from "react";

import { ScrollElement } from "@components/ScrollElement";

import { FullWidthBannerProps } from "../..";
import { ImageBackground } from "../components";

import * as S from "../styles";

const FullWidthBanner = ({
  slice,
}: FullWidthBannerProps): JSX.Element | null => {
  const { image, slice_id } =
    slice.primary as unknown as FullWidthBannerSliceImageOnlyPrimary;

  if (!image?.url) {
    return null;
  }

  return (
    <S.FullWidthBanner>
      <ScrollElement id={(slice_id as string) || "full-Width-banner"} />
      <ImageBackground image={image} />
    </S.FullWidthBanner>
  );
};

export default FullWidthBanner;
