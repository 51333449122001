import { paths } from "@paths";
import { asLink } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import {
  FullWidthBannerSliceTextOverlayLightPrimary,
  FullWidthBannerSliceTextOverlayPrimary,
} from "prismicio-types";
import React from "react";

import { ButtonVariant } from "@components/Button/styles";
import { ButtonLink } from "@components/ButtonLink";
import { Container } from "@components/Container";

import * as S from "../../styles";

type TextOverlayVariantVariation =
  | FullWidthBannerSliceTextOverlayPrimary
  | FullWidthBannerSliceTextOverlayLightPrimary;

export type TextOverlayVariant = "default" | "light";

type TextOverlayVariantProps = {
  buttonType: ButtonVariant;
  overlayVariant: TextOverlayVariant;
} & Omit<TextOverlayVariantVariation, "image" | "slice_id">;

export const TextOverlay = ({
  title,
  description,
  overlayVariant,
  button_active,
  button_link,
  button_text,
  buttonType,
  opacity,
}: TextOverlayVariantProps) => (
  <S.FullWidthBannerTextOverlay opacity={opacity} variant={overlayVariant}>
    <Container
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <S.TextOverImageInnerWrapper>
        {title && <PrismicRichText field={title} />}
        {description && <PrismicRichText field={description} />}
        {button_active && asLink(button_link) && (
          <ButtonLink
            className="button-link"
            href={asLink(button_link) || paths.home}
            variant={buttonType}
          >
            {button_text}
          </ButtonLink>
        )}
      </S.TextOverImageInnerWrapper>
    </Container>
  </S.FullWidthBannerTextOverlay>
);
