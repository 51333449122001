import Default from "./Default";
import ImageOnly from "./ImageOnly";
import Light from "./Light";
import TextOverlay from "./TextOverlay";
import TextOverlayLight from "./TextOverlayLight";
import VideoBanner from "./VideoBanner";

export {
  Default,
  ImageOnly,
  Light,
  TextOverlay,
  TextOverlayLight,
  VideoBanner,
};

export const components = {
  default: Default,
  imageOnly: ImageOnly,
  light: Light,
  textOverlay: TextOverlay,
  textOverlayLight: TextOverlayLight,
  videoBanner: VideoBanner,
};
